import {
  Anchor,
  FlexBox,
  GridForm,
  GridFormField,
  Text,
} from '@codecademy/gamut';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GuardedCaptchaProvider } from '~/libs/captchaGuardedRequests/GuardedCaptchaProvider';
import { sendCaptchaGuardedRequest } from '~/libs/captchaGuardedRequests/sendCaptchaGuardedRequest';
import {
  CaptchaGuardedResult,
  CaptchaResponse,
} from '~/libs/captchaGuardedRequests/types';
import { ccdata } from '~/libs/ccdata';
import { trackUserClick } from '~/libs/eventTracking';
import { genericRequest } from '~/libs/genericRequest';
import {
  businessSSOLoginUrl,
  forgotPasswordUrl,
  learnPath,
  loginPath,
  passwordBreachPath,
} from '~/libs/urlHelpers';
import { addAlert, closeAlert } from '~/state/alerts/actions';
import { selectAlerts } from '~/state/alerts/selectors';
import { ServerAlert } from '~/state/alerts/types';
import { selectLocationType } from '~/state/location/selectors';

import { OauthButtonGroup } from '../OauthButtonGroup';
// eslint-disable-next-line gamut/no-css-standalone
import styles from './styles.module.scss';
import { LoginFormProps, ResponseBody } from './types';

const loginAlert: ServerAlert = {
  type: 'error',
  message: 'Invalid email or password',
};

const loggedOutAlert: ServerAlert = {
  type: 'general',
  message: 'Signed out successfully.',
};

interface LoginPost {
  payload: Record<string, string | boolean | FileList | undefined>;
  captchaToken?: string;
  captchaVersion?: 'v2' | 'v3';
}

const redirectAfterLogIn = (body: ResponseBody) => {
  const isBreached = body.password_is_breached;
  const serverRedirect = body.redirect_url;

  const redirectPath = isBreached
    ? passwordBreachPath
    : serverRedirect || learnPath();

  window.location.assign(redirectPath);
};

export const LoginForm: React.FC<LoginFormProps> = ({
  redirectUrl,
  onSuccess = redirectAfterLogIn,
  reminderEmailData,
  trackingData,
}) => {
  const [captcha, setCaptcha] = useState<React.ReactNode>(undefined);
  const authenticityToken = ccdata.get('authenticity_token');
  const alerts = useSelector(selectAlerts);
  const locationType = useSelector(selectLocationType);
  const dispatch = useDispatch();

  const ssoRequiredAlert: ServerAlert = {
    type: 'error',
    message: 'Must log in with SAML SSO',
    link: `${businessSSOLoginUrl(redirectUrl)}`,
  };

  const alertToDisplay = alerts.find((alert) => alert.message);

  const onFailedLogin = () => {
    dispatch(addAlert(loginAlert));
  };

  const removeAlerts = () => {
    dispatch(closeAlert(loginAlert));
    dispatch(closeAlert(loggedOutAlert));
    dispatch(closeAlert(ssoRequiredAlert));
  };
  const loginPost = async ({
    payload,
    captchaToken,
    captchaVersion,
  }: LoginPost): Promise<CaptchaResponse<ResponseBody>> => {
    const response = await genericRequest({
      data: {
        captcha_token: captchaToken,
        captcha_version: captchaVersion,
        ...payload,
        reminder_email: reminderEmailData,
        tracking_data: omit(trackingData, 'content_ids'),
      },
      endpoint: loginPath,
      method: 'POST',
    });
    const result = await response.json();
    if (!response.ok) {
      if (result.message?.includes('SAML SSO')) {
        return { error: new Error(result.message), allowed: false };
      }
      return {
        allowed: false,
      };
    }
    return {
      allowed: true,
      result: result as ResponseBody,
    };
  };
  const submitForm = async (
    payload: Record<string, string | boolean | FileList | undefined>
  ): Promise<CaptchaGuardedResult<ResponseBody>> => {
    try {
      const [captcha, promise] = sendCaptchaGuardedRequest({
        action: 'login',
        requestV2: (captchaToken) =>
          loginPost({
            captchaVersion: 'v2',
            captchaToken,
            payload,
          }),
        requestV3: (captchaToken) =>
          loginPost({
            captchaVersion: 'v3',
            captchaToken,
            payload,
          }),
      });
      setCaptcha(captcha);
      const response = await promise;
      setCaptcha(undefined);
      if (response.status === 'complete') {
        return response;
      }
      if (response.status === 'failed') {
        return {
          status: 'failed',
          error: response.error ?? new Error('login post failed'),
        };
      }
      return { status: 'blocked' };
    } catch (error) {
      setCaptcha(undefined);
      return { error, status: 'failed' };
    }
  };

  const submitLogin: SubmitHandler<
    Record<string, string | boolean | FileList | undefined>
  > = async (values) => {
    removeAlerts();
    const payload = {
      ...values,
      redirect: redirectUrl,
      authenticity_token: authenticityToken,
    };
    const defaultTracking = { target: 'login_account' };
    trackUserClick({ ...defaultTracking, ...trackingData });

    try {
      const response = await submitForm(payload);
      if (response.status === 'complete') {
        onSuccess(response.result);
      } else if (
        response.status === 'failed' &&
        response.error?.message.includes('SAML SSO')
      ) {
        dispatch(addAlert(ssoRequiredAlert));
      } else onFailedLogin();
    } catch (error) {
      if (error.response.text.includes('SAML SSO')) {
        dispatch(addAlert(ssoRequiredAlert));
      } else onFailedLogin();
    }
  };

  const fieldArray: GridFormField[] = [
    {
      type: 'text',
      name: 'user[login]',
      size: 12,
      label: 'Email or username',
      id: 'user_login',
      validation: {
        required: true,
      },
    },
    {
      type: 'password',
      name: 'user[password]',
      size: 12,
      label: 'Password',
      id: 'login__user_password',
      validation: {
        required: true,
      },
    },
    {
      type: 'custom',
      render: () => (
        <Text
          textColor="red-600"
          className={styles.errorText}
          data-testid="error-text"
          aria-live="polite"
        >
          {alertToDisplay?.link ? (
            <Anchor href={alertToDisplay.link}>{alertToDisplay.message}</Anchor>
          ) : (
            alertToDisplay?.message
          )}
        </Text>
      ),
      name: 'errors',
      size: 12,
      hideLabel: true,
    },
    {
      type: 'custom',
      render: () => (
        <a
          className={styles.forgotPassword}
          href={forgotPasswordUrl()}
          target={
            locationType === 'location/COURSE_CONTENT_ITEM'
              ? '_blank'
              : undefined
          }
          rel="noreferrer"
        >
          I forgot my password
        </a>
      ),
      name: 'reset_password',
      size: 12,
      hideLabel: true,
    },
  ];

  return (
    <GuardedCaptchaProvider>
      <div data-testid="login-form-container">
        <FlexBox column textAlign="left" pb={16} px={4}>
          <GridForm
            fields={fieldArray.filter((field) =>
              alerts.length ? true : field.name !== 'errors'
            )}
            onSubmit={submitLogin}
            submit={{
              disabled: ({ isSubmitting }) => isSubmitting,
              contents: 'Log in',
              size: 6,
            }}
            data-testid="login-form"
            className={styles.loginForm}
          />
        </FlexBox>
        {captcha}
        <FlexBox column>
          <OauthButtonGroup
            trackingData={trackingData}
            urlParams={{
              redirectUrl,
              ...reminderEmailData,
            }}
          />
        </FlexBox>
      </div>
    </GuardedCaptchaProvider>
  );
};
